@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  width: 100%;
  height: 100%;
  background: rgb(2 6 23 /1);
}
input[type="range"]::-webkit-slider-thumb {
  pointer-events: all;
  width: 24px;
  height: 24px;
  -webkit-appearance: none;
  /* @apply w-6 h-6 appearance-none pointer-events-auto; */
}
#root {
  height: 100%;
  width: 100%;
}
div.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.marquee {
  display: flex;
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: scroll 25s linear infinite;
}
.glow {
  font-weight: bold;
  color: #00ffff; /* Cyan color for the text */
  box-shadow: 0 0 5px #00ffff, 0 0 10px #00ffff, 0 0 15px #00ffff,
    0 0 20px #00ffff;
  animation: glow-animation 1s infinite alternate; /* Quicker animation */
}

@keyframes glow-animation {
  from {
    box-shadow: 0 0 5px #00ffff, 0 0 10px #00ffff, 0 0 15px #00ffff,
      0 0 20px #00ffff;
  }
  to {
    box-shadow: 0 0 10px #00ffff, 0 0 20px #00ffff, 0 0 30px #00ffff,
      0 0 40px #00ffff;
  }
}
@keyframes scroll {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(
      -50%
    ); /* Moves by 50% because we duplicated the content */
  }
}
.no-select {
  user-select: none; /* Disable text selection */
  -webkit-user-select: none; /* For Safari */
  -moz-user-select: none; /* For Firefox */
  -ms-user-select: none; /* For IE/Edge */
}
