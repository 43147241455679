/* Base swiper styles */
.swiper {
  width: 100%;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 17rem;
}

/* For home-page class */
.home-page .swiper-slide {
  background-position: center;
  background-size: cover;
  width: 350px !important;
  height: calc(90% - 4rem) !important;
}
.home-page .swiper-slide.active-slide {
  transform: scale(1.2) !important;
  transition-delay: 0.3s;
  z-index: 1000 !important;
}
/* Scale down for high-density screens */
@media only screen and (min-resolution: 120dpi),
  only screen and (min-device-pixel-ratio: 1.25),
  only screen and (min-resolution: 1.25dppx) {
  .swiper-slide {
    width: 14rem;
  }
  .home-page .swiper-slide {
    width: 17rem !important;
  }
}
@media only screen and (min-resolution: 150dpi),
  only screen and (min-device-pixel-ratio: 1.5),
  only screen and (min-resolution: 1.5dppx) {
  .swiper-slide {
    width: 11rem;
  }
}

/* Mobile overrides */
@media (max-width: 768px) {
  .swiper-slide {
    width: 14rem !important;
    height: auto !important;
  }
  .home-page .swiper-slide {
    width: 8.5rem !important;
    height: 15rem !important;
  }
  .home-page .swiper-slide.active-slide {
    transform: scale(1.4) !important;
    transition-delay: 0.3s;
    z-index: 1000 !important;
  }
  .home-page .swiper-wrapper {
    padding-top: 10vh;
    align-items: end;
  }
}

/* Additional styling */
.home-page .swiper-wrapper {
  /* padding-top: 10vh; */
  align-items: center;
}

.home-page .swiper.swiper-coverflow {
  z-index: -1;
}

.swiper-3d .swiper-slide-shadow-left,
.swiper-3d .swiper-slide-shadow-right {
  background-image: none;
}

.swiper-slide img {
  display: block;
  width: 100%;
}
